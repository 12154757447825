
import Vue from "vue";
const { __getText } = Vue.prototype;

export default Vue.extend({
  name: "PreviewLoading",
  computed: {
    loadingString(): string {
      return this.$route.params.loadingString;
    },
    loadingMessage(): string {
      return this.loadingString ? __getText("quote", this.loadingString) : "";
    }
  }
});
